import axios from "axios";
import { logoutUrl } from "../Api";
import { getToken } from "../setting";

export const logoutAction = () => {
    const tokenHeader = getToken();
  
    return new Promise((resolve, reject) => {
      axios
        .get(logoutUrl, tokenHeader)
        .then((response) => {
          resolve(response.data); // Resolve the Promise with the response data
        })
        .catch((error) => {
          reject(error); // Reject the Promise with the error
        });
    });
  };