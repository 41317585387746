import { Button, Form, Input, Spin, notification } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMeAction, signInUserAction } from "./Action";
import { validateEmail } from "../../Utils/setting";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { logedInUser } from "../../App";

export default function Login() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const { userData, setUserData } = useContext(logedInUser);

  const [loading, setLoading] = useState(false);

  // const validateEmail = (rule, value, callback) => {
  //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(value)) {
  //     callback(); // Validation passed
  //   } else {
  //     callback('Invalid email format'); // Validation failed
  //   }
  // };

  const onFinish = async (values) => {
    setLoading(true);

    const payload = {
      username: values.email,
      password: values.password,
    };

    await signInUserAction({ payload })
      .then(async ({ message, error, data }) => {
        if (!error) {
          localStorage.setItem("userToken", JSON.stringify(data.token));

          getMeAction({ token: data.token }).then(
            ({ error: error2, data: data2 }) => {
              if (!error2) {
                localStorage.setItem("loginUser", JSON.stringify(data2));
                setUserData(data2);
                navigate("/subscription-details");
              } else {
                notification.error({ message: `Sign In failed: ${error2}` });
              }
            },
          );
        } else notification.error({ message });
      })
      .catch((error) => {
        notification.error({ message: `Sign In failed: ${error}` });
      });

    setLoading(false);
  };

  const toggleShowPassword = () => {
    setShowPassword((e) => !e);
  };
  //
  return (
    <div class="flex w-[28rem] flex-col px-6  lg:px-8 mt-20">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <img
          class="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        /> */}
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div class=" sm:mx-auto sm:w-full sm:max-w-sm">
        <Form
          onFinish={onFinish}
          autoComplete="off"
          className="font-sans text-light dark:text-dark mt-10"
          // initialValues={{ email: "apachiheart@gmail.com", password: "asdf" }}
        >
          <Form.Item name={"email"}>
            <input
              placeholder="Email address"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
            />
          </Form.Item>

          <div>
            <div className="relative items-center content-center">
              <Form.Item name={"password"}>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autocomplete="current-password"
                  required
                  placeholder="Password"
                  class="block mt-2 w-full rounded-md border-0 p-1.5 py-3 px-3 text-light focus:outline-none shadow-sm placeholder:text-gray-400 ring-1 ring-blur focus:ring-2 focus:ring-offset-inherit focus:ring-primary sm:text-sm sm:leading-6"
                />
              </Form.Item>
              <div class="flex items-center justify-between mt-10">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                ></label>
                <div class="text-sm">
                  <a
                    onClick={() => navigate("/reset_password")}
                    className=" text-highlight font-semibold hover:cursor-pointer hover:text-highlight"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              {showPassword ? (
                <EyeInvisibleOutlined
                  onClick={toggleShowPassword}
                  className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
                />
              ) : (
                <EyeOutlined
                  onClick={toggleShowPassword}
                  className="text-light absolute top-[1.40rem] right-3 text-xl hover:cursor-pointer"
                />
              )}
            </div>
            {/* FFf */}
          </div>

          <Form.Item>
            <button
              type="submit"
              class="flex w-full bg-primary_button justify-center rounded-md bg-indigo-600 py-3 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-button_primary mt-5"
            >
              Sign In
            </button>
          </Form.Item>
        </Form>
        <form class="space-y-6" action="#" method="POST"></form>

        <p class="mt-10 text-center text-sm text-gray-500">
          Don’t have an account yet?{" "}
          <a
            onClick={() => navigate("/signup")}
            className=" text-highlight font-semibold hover:cursor-pointer"
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
}
