import { LockOutlined, SmileFilled } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setNewPasswordAction } from "./Action";

export default function ResetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [api, contextHolder] = notification.useNotification();

  const token = searchParams.get("new_jwt_token");

  useEffect(() => {
    if (!token) navigate("/login", { replace: true });
  }, [token]);

  const onFinish = (values) => {
    const params = {
      token: token,
      password1: values.password,
      password2: values.confirmPassword,
    };

    setNewPasswordAction({ payload: params })
      .then(({ error, message }) => {
        if (!error) {
          api.open({
            message: "Password Changed Successfully",
            description: "Redirecting To Login Screen",
            key: "asdf",
            icon: <SmileFilled />,
          });

          setTimeout(() => {
            api.destroy("asdf");
            navigate("/Login", { replace: true });
          }, 3000);
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log("err", err));
  };

  const validatePassword = (rule, value, callback) => {
    const password = value;
    const confirmPassword = form.getFieldValue("confirmPassword"); // Get the value of the Confirm Password field
    if (password && confirmPassword && password !== confirmPassword) {
      callback("Passwords do not match");
    } else {
      callback();
    }
  };

  return (
    <>
      {contextHolder}
      <LockOutlined className=" text-5xl mt-28" />
      {/* <ExclamationCircleOutlined className=' text-5xl mb-5' /> */}
      <h1 className="text-xl font-bold leading-tight tracking-tight text-center mb-5">
        Set New Password
      </h1>

      <Form onFinish={onFinish} autoComplete="off" className="w-72 font-sans ">
        <p className=" text-primary">Password:</p>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
            {
              validator: validatePassword, // Use the custom validator
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <p className=" text-primary">Confirm Password:</p>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            {
              validator: validatePassword, // Use the custom validator
            },
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button
            // loading={loading}
            htmlType="submit"
            className="w-full h-10 mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm py-2.5 text-center"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
