import {
  DownloadOutlined,
  WindowsFilled,
  DiscordOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  cancelSubscriptionAction,
  createSubscriptionAction,
  getAllMySubscriptionsAction,
  getAllSubscriptionAction,
} from "./apiAction";
import { Spin, notification, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import {
  billingCycleEnums,
  currencyEnums,
  getKeyByValue,
  logoutUser,
  TM_INSTALLER_NAME,
} from "../../Utils/setting";
import { UserOutlined } from "@ant-design/icons";

import TWS_LOGO from "../../Assets/tws_logo.png";
import DISCORD_LOGO from "../../Assets/discord.svg";
import dayjs from "dayjs";

export default function SubscriptionDetails() {
  const navigate = useNavigate();

  // Cache Memmory
  const cacheActiveSub = localStorage.getItem("activeSubscription");
  const [notActiveWithRemainingDays, setNotActiveWithRemainingDays] = useState(
    {},
  );
  const [activeSubscription, setActiveSubscription] = useState(
    cacheActiveSub ? JSON.parse(cacheActiveSub) : [],
  );

  const userData = JSON.parse(localStorage.getItem("loginUser"));

  const getMySubscription = async () => {
    await getAllMySubscriptionsAction()
      .then(({ message, error, data }) => {
        if (!error) {
          const activeSub = data.filter((vv) => vv.is_subscription_active);
          console.log(activeSub, "activeSub");
          if (activeSub.length === 0) {
            localStorage.removeItem("activeSubscription");
            setActiveSubscription(activeSub);
          } else {
            setActiveSubscription(activeSub);
            localStorage.setItem(
              "activeSubscription",
              JSON.stringify(activeSub),
            );
          }

          const notActiveWithRemainingDays = data.find((v) => {
            const expiryDate = dayjs(
              v.subscription_key_details.expiry,
              "YYYY-MM-DD",
            );
            const currentDate = dayjs();
            const difference = expiryDate.diff(currentDate);
            return difference > 0 && !v.is_subscription_active;
          });

          if (notActiveWithRemainingDays) {
            setNotActiveWithRemainingDays(notActiveWithRemainingDays);
          }
        } else {
          notification.error({ message });
        }
      })
      .catch((error) => {
        logoutUser({ navigate: navigate, error: error });
      });
  };

  useEffect(() => {
    getMySubscription();
  }, []);

  return (
    <div className="flex flex-col  w-full justify-center items-center mt-10">

      <div class="bg-primary border-l-4 fixed flex items-center justify-center mx-12 p-2 pc-nav shadow-lg text-black w-full z-10" style={{top: "79px"}}>
        <p class="text-center text-sm">A new version of the bot has been released. Download it to enjoy the latest features and improvements.</p>
      </div>

      <div class="-mb-20 bg-primary border-l-4 flex items-center justify-center mobile-nav-ctr mx-12 p-2 shadow-lg text-black w-full z-10" style={{top: "79px"}}>
        <p class="text-center text-sm">A new version of the bot has been released. Download it to enjoy the latest features and improvements.</p>
      </div>

      <h2 className="text-3xl font-semibold mt-24">Account Details</h2>

      <div className="w-full max-w-sm bg-white dark:bg-dark border-gray-200 dark:border-y-white rounded-lg shadow">
        <div className="flex flex-col items-center py-10 ">
          <Avatar
            //f
            className=" text-primary text-center mb-5 bg-primary dark:bg-dark border-2 border-white "
            size={80}
            icon={<UserOutlined />}
          />
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {userData?.username}
          </span>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            {`${userData?.firstname} ${userData?.lastname}`}
          </h5>
          <p className="text-xs">{userData?.email}</p>
          <div className="flex mt-4 md:mt-6">
            <p className="text-xs text-light dark:text-dark">
              Forgot Password?{" "}
              <span
                onClick={() => {
                  navigate("/reset_password");
                }}
                className="underline cursor-pointer text-primary_highlight font-semibold"
              >
                Reset Now
              </span>{" "}
            </p>
          </div>
        </div>
      </div>

      {activeSubscription.length === 0 ? (
        Object.values(notActiveWithRemainingDays).length === 0 ? (
          <>
            <h2 className="text-3xl font-semibold mt-20">
              No Active Subscription
            </h2>
            <p className="mt-5 text-center">
              Currently, there is no active subscription. To review pricing,
              please click on the following link:{" "}
              <span
                onClick={() => navigate("/subscription")}
                className="text-highlight font-semibold hover:cursor-pointer"
              >
                Click Here
              </span>
            </p>
          </>
        ) : (
          <ActiveSubscription
            activeSubscription={[notActiveWithRemainingDays]}
            getMySubscription={getMySubscription}
            notActiveWithRemainingDays={true}
          />
        )
      ) : (
        <ActiveSubscription
          activeSubscription={activeSubscription}
          getMySubscription={getMySubscription}
        />
      )}
    </div>
  );
}

const ActiveSubscription = ({
  activeSubscription,
  getMySubscription,
  notActiveWithRemainingDays,
}) => {
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  const cancelSubscription = async () => {
    setLoading(true);

    await cancelSubscriptionAction({
      sub_id: activeSubscription[0].stripe_subscription_id,
    })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({ message });
          getMySubscription();
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log("err", err));

    setLoading(false);
  };

  const renewSubscription = async () => {
    let allSubs;
    try {
      const { error, message, data } = await getAllSubscriptionAction();

      if (!error) {
        allSubs = data.map((item, index) => {
          if (index === 0) return item;
          else if (index === 1) return data[index + 1];
          else if (index === 2) return data[index - 1];
        });
      } else {
        notification.error({ message });
      }
    } catch (error) {
      notification.error({ message: error });
    }

    const subFound = allSubs.find(
      (item) => item.price === activeSubscription[0].subscription_price,
    );

    if (subFound) {
      await createSubscriptionAction({
        payload: {
          subscription_product_id: subFound.subscription_product_id,
        },
      })
        .then(({ error, message, data }) => {
          if (!error) {
            window.location.href = data.url;
          } else {
            notification.error({ message });
          }
        })
        .catch((err) => console.log("err", err));
    }
  };

  return (
    <>
      <h2 className="text-3xl font-semibold mt-20"> Current Subscription</h2>
      {activeSubscription.map((vv) => (
        <div className="p-10 flex flex-row justify-between">
          <div
            style={{
              minHeight: 250,
            }}
            className="mx-5 bg-purple-500 text-white rounded-lg border-4 border-primary w-56 relative drop-shadow-default  hover:scale-105 transition-transform duration-300"
          >
            <div className="p-4 pt-10 pb-10">
              <h1 className="text-2xl font-bold text-center">
                {getKeyByValue(billingCycleEnums, vv.billing_cycle)}
              </h1>
              <div className="text-black mt-4 text-5xl font-semibold relative ml-2 flex flex-row items-center justify-center ">
                <p className="text-center relative">
                  {" "}
                  <span
                    style={{
                      left: -10,
                    }}
                    className="absolute text-base font-normal"
                  >
                    {vv.currency === currencyEnums["INR"] ? "₹" : "$"}
                  </span>
                  {vv.subscription_price}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                width: "100%",
                bottom: -22,
              }}
              className="absolute "
            >
              <button className="shine-button shine-button-disabled text-white py-2 px-4 rounded bg-primary_button text-button_primary w-40 rounded-l-3xl rounded-r-3xl drop-shadow-default font-semibold">
                {vv.is_subscription_paused ? "Paused" : "Active Plan"}
              </button>
            </div>
          </div>
        </div>
      ))}
      {/*  */}
      <div className="flex flex-col  w-full justify-center items-center mt-7">
        {activeSubscription[0].billing_cycle !==
          billingCycleEnums["LIFETIME"] && (
          <Spin size="large" tip="Loading..." spinning={false}>
            <div className=" mb-10 font-sans action-btns">
              {notActiveWithRemainingDays ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      transition: "all 0.1s ease-in",
                      letterSpacing: 1,
                    }}
                    className="drop-shadow-default border-2 px-12 py-2 rounded-md font-semibold mx-5 "
                  >
                    Subscription cancelled. License key will work until{" "}
                    {dayjs(
                      activeSubscription[0].subscription_key_details.expiry,
                    ).format("DD MMM YYYY")}
                  </div>
                  <button
                    onClick={() => renewSubscription()}
                    style={{
                      transition: "all 0.1s ease-in",
                      letterSpacing: 1,
                    }}
                    className="drop-shadow-default border-2 px-12 py-2 rounded-md hover:bg-primary_button hover:text-button_primary font-semibold mx-5 "
                  >
                    Renew
                  </button>
                </div>
              ) : (
                <button
                  onClick={cancelSubscription}
                  style={{
                    transition: "all 0.1s ease-in",
                    letterSpacing: 1,
                  }}
                  className="drop-shadow-default border-2 px-12 py-2 rounded-md hover:bg-primary_button hover:text-button_primary font-semibold mx-5 "
                >
                  Cancel Subscription
                </button>
              )}
            </div>
          </Spin>
        )}
      </div>
      <div
        className="text-center flex flex-col mt-5 key-ctr"
        style={{ width: "60%" }}
      >
        <p
          className="text-lg font-semibold header-text"
          style={{
            letterSpacing: 1,
          }}
        >
          Your Subscription Key
        </p>
        {activeSubscription[0].is_subscription_paused ? (
          <div className="bg-primary h-9 rounded-xl flex items-center justify-center p-2 text-dark">
            <p>
              To access your key, please resume your subscription, which is
              currently on pause.
            </p>
          </div>
        ) : (
          <>
            {activeSubscription[0].subscription_key_details?.app_key ? (
              <div className="flex flex-1 justify-center mb-5 items-center mobile-key-ctr">
                <div className="text-overflow bg-primary h-9 rounded-xl flex flex-row justify-center px-5 items-center p-2 w-96 key-holder">
                  {activeSubscription[0].subscription_key_details?.app_key}
                </div>
                <CopyToClipboard
                  text={activeSubscription[0].subscription_key_details?.app_key}
                  onCopy={handleCopy}
                >
                  <button className="ml-2 px-4 py-1 text-white rounded-md border-2 hover:bg-primary transition-all  ">
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </CopyToClipboard>
              </div>
            ) : (
              <div className="bg-primary h-9 rounded-xl flex items-center justify-center p-2 text-dark key-holder">
                <p>
                  Unable to retrieve the key at the moment. Please try again
                  later.
                </p>
              </div>
            )}
            <div className="text-center flex flex-col mt-5 mobile-download">
              <p>
                {" "}
                Get ahead with our cutting-edge software by downloading the
                latest version today!
              </p>
              <div className="flex flex-row flex-1 justify-center mb-10 text-light dark:text-dark">
                <a
                  href="https://www.interactivebrokers.com/en/trading/tws-updateable-latest.php"
                  target="_blank"
                  className="w-[150px]  py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary mr-5 text-center flex items-center justify-center flex-col"
                  style={{ transition: "all 0.1s ease-in" }}
                  rel="noreferrer"
                >
                  <img src={TWS_LOGO} alt="TWS" className="h-[50px]" />
                  <p className="font-semibold  ">Download TWS</p>
                </a>
                <a
                  href={`https://app.tradewithmaster.com/static/${TM_INSTALLER_NAME}.exe`}
                  target="_blank"
                  className="w-[150px] py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary mr-5 text-center"
                  style={{ transition: "all 0.1s ease-in" }}
                  rel="noreferrer"
                >
                  <WindowsFilled
                    className=""
                    style={{
                      fontSize: 50,
                    }}
                  />
                  <p className="font-semibold  ">Download TradeMaster</p>
                </a>
                <a
                  target="_blank"
                  href="https://app.tradewithmaster.com//static/Instructions_for_Connecting_Trademaster_Bot_with_TWS.pdf"
                  className=" w-[150px]  py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary mr-5 text-center"
                  style={{ transition: "all 0.1s ease-in" }}
                  rel="noreferrer"
                >
                  <DownloadOutlined
                    className=""
                    style={{
                      fontSize: 50,
                    }}
                  />
                  <p className="font-semibold  ">Download Instructions</p>
                </a>
                <a
                  href="https://app.tradewithmaster.com//static/FAQ_Frequently Asked Questions.pdf"
                  target="_blank"
                  className=" w-[150px]  py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary mr-5 text-center"
                  style={{ transition: "all 0.1s ease-in" }}
                  rel="noreferrer"
                >
                  <DownloadOutlined
                    className=""
                    style={{
                      fontSize: 50,
                    }}
                  />
                  <p className="font-semibold">
                    Download <br /> FAQ
                  </p>
                </a>
                <a
                  href="https://discord.gg/QJzatN95ub"
                  target="_blank"
                  className="w-[150px]  py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary mr-5 text-center flex items-center justify-center flex-col"
                  style={{ transition: "all 0.1s ease-in" }}
                  rel="noreferrer"
                >
                  <img src={DISCORD_LOGO} alt="TWS" className="h-[50px]" />
                  <p className="font-semibold mt-2">Join Discord</p>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
